import React from 'react'

import Form from '../form'

import { Link } from 'gatsby'

import Input from '../input'

import styles from './style.module.scss'

import Select from '../select'

import Button from '../button'

import QuestionImage from '../../images/question.svg'

import PhoneInput from '../phone-input'

export default () => {
    return (
        <div className={styles.postEnquiryForm}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <h1>
                            Sell without third parties –Seller to Buyer, no
                            middle men
                        </h1>
                        <Form
                            className={styles.form}
                            checkboxes={['accept_privacy_policy']}
                            requiredFields={[
                                'full_name',
                                'email',
                                'phone',
                                'accept_privacy_policy'
                            ]}
                            errorMessageClass={styles.errorMessage}
                        >
                            <p>
                                Are you a seller and have a property to sell,
                                have a buyer already and have negotiated the
                                price and terms of sale.
                            </p>
                            <p>
                                Are you a buyer in the same condition. You need
                                contracts and an escrow agent to conclude your
                                sale smoothly, safely and with no hassle.
                            </p>
                            <p>
                                Save on fees and pay only 10,000 AED regardless
                                of the size and value of the property for a full
                                conveyancing service including contract
                                preparation, handling all developer
                                relationships and other related activity. (This
                                fee excludes any government, trustee or transfer
                                fees)
                            </p>
                            <Input type="text" label="Full name" required />
                            <Input type="email" label="Email" required />
                            <PhoneInput />
                            <Input
                                type="text"
                                label="Property title"
                                required
                            />
                            <Select label="Best time to call you">
                                <option value="Morning">Morning</option>
                                <option value="Afternoon">Afternoon</option>
                                <option value="Evening">Evening</option>
                            </Select>
                            <Select label="Property type">
                                <option value="Apartment">Apartment</option>
                                <option value="Hotel Apartment">
                                    Hotel Apartment
                                </option>
                                <option value="Building">Building</option>
                                <option value="Commercial Building">
                                    Commercial Building
                                </option>
                                <option value="Land">Land</option>
                                <option value="Office">Office</option>
                                <option value="Penthouse">Penthouse</option>
                                <option value="Residential Building">
                                    Residential Building
                                </option>
                                <option value="Studio">Studio</option>
                                <option value="Townhouse">Townhouse</option>
                                <option value="Villa">Villa</option>
                                <option value="Warehouse">Warehouse</option>
                                <option value="Others">Others</option>
                            </Select>
                            <Select label="Looking to">
                                <option value="Sell">Sell</option>
                                <option value="Lease">Lease</option>
                            </Select>
                            <Input type="number" label="Budget" />
                            <Input type="text" label="Bedrooms" />
                            <Select label="Select area" multiple>
                                <option value="Abu Hail">Abu Hail</option>
                                <option value="Al Awir First">
                                    Al Awir First
                                </option>
                                <option value="Al Awir Second">
                                    Al Awir Second
                                </option>
                                <option value="Al Bada">Al Bada</option>
                                <option value="Al Baraha">Al Baraha</option>
                                <option value="Al Barsha First">
                                    Al Barsha First
                                </option>
                                <option value="Al Barsha Second">
                                    Al Barsha Second
                                </option>
                                <option value="Al Barsha South First">
                                    Al Barsha South First
                                </option>
                                <option value="Al Barsha South Second">
                                    Al Barsha South Second
                                </option>
                                <option value="Al Barsha South Third">
                                    Al Barsha South Third
                                </option>
                                <option value="Al Barsha Third">
                                    Al Barsha Third
                                </option>
                                <option value="Al Buteen">Al Buteen</option>
                                <option value="Al Dhagaya">Al Dhagaya</option>
                                <option value="Al Garhoud">Al Garhoud</option>
                                <option value="Al Guoz Fourth">
                                    Al Guoz Fourth
                                </option>
                                <option value="Al Hamriya, Dubai">
                                    Al Hamriya, Dubai
                                </option>
                                <option value="Al Hamriya Port">
                                    Al Hamriya Port
                                </option>
                                <option value="Al Hudaiba">Al Hudaiba</option>
                                <option value="Al Jaddaf">Al Jaddaf</option>
                                <option value="Al Jafiliya">Al Jafiliya</option>
                                <option value="Al Karama">Al Karama</option>
                                <option value="Al Khabisi">Al Khabisi</option>
                                <option value="Al Khwaneej First">
                                    Al Khwaneej First
                                </option>
                                <option value="Al Khwaneej Second">
                                    Al Khwaneej Second
                                </option>
                                <option value="Al Kifaf">Al Kifaf</option>
                                <option value="Al Mamzar">Al Mamzar</option>
                                <option value="Al Manara">Al Manara</option>
                                <option value="Al Mankhool">Al Mankhool</option>
                                <option value="Al Merkad">Al Merkad</option>
                                <option value="Al Mina">Al Mina</option>
                                <option value="Al Mizhar First">
                                    Al Mizhar First
                                </option>
                                <option value="Al Mizhar Second">
                                    Al Mizhar Second
                                </option>
                                <option value="Al Muraqqabat">
                                    Al Muraqqabat
                                </option>
                                <option value="Al Murar">Al Murar</option>
                                <option value="Al Mushrif">Al Mushrif</option>
                                <option value="Al Muteena First">
                                    Al Muteena First
                                </option>
                                <option value="Al Muteena Second">
                                    Al Muteena Second
                                </option>
                                <option value="Al Sabkha">Al Sabkha</option>
                                <option value="Al Muteena">Al Muteena</option>
                                <option value="Al Nahda First">
                                    Al Nahda First
                                </option>
                                <option value="Al Nahda Second">
                                    Al Nahda Second
                                </option>
                                <option value="Al Nasr, Dubai">
                                    Al Nasr, Dubai
                                </option>
                                <option value="Al Quoz First">
                                    Al Quoz First
                                </option>
                                <option value="Al Quoz Industrial First">
                                    Al Quoz Industrial First
                                </option>
                                <option value="Al Quoz Industrial Fourth">
                                    Al Quoz Industrial Fourth
                                </option>
                                <option value="Al Quoz Industrial Second">
                                    Al Quoz Industrial Second
                                </option>
                                <option value="Al Quoz Industrial Third">
                                    Al Quoz Industrial Third
                                </option>
                                <option value="Al Quoz Second">
                                    Al Quoz Second
                                </option>
                                <option value="Al Quoz Third">
                                    Al Quoz Third
                                </option>
                                <option value="Al Qusais First">
                                    Al Qusais First
                                </option>
                                <option value="Al Qusais Industrial Fifth">
                                    Al Qusais Industrial Fifth
                                </option>
                                <option value="Al Qusais Industrial First">
                                    Al Qusais Industrial First
                                </option>
                                <option value="Al Qusais Industrial Fourth">
                                    Al Qusais Industrial Fourth
                                </option>
                                <option value="Al Qusais Industrial Second">
                                    Al Qusais Industrial Second
                                </option>
                                <option value="Al Qusais Industrial Third">
                                    Al Qusais Industrial Third
                                </option>
                                <option value="Al Qusais Second">
                                    Al Qusais Second
                                </option>
                                <option value="Al Qusais Third">
                                    Al Qusais Third
                                </option>
                                <option value="Al Raffa">Al Raffa</option>
                                <option value="Al Ras">Al Ras</option>
                                <option value="Al Rashidiya">
                                    Al Rashidiya
                                </option>
                                <option value="Al Rigga">Al Rigga</option>
                                <option value="Al Safa First">
                                    Al Safa First
                                </option>
                                <option value="Al Safa Second">
                                    Al Safa Second
                                </option>
                                <option value="Al Safouh First">
                                    Al Safouh First
                                </option>
                                <option value="Al Safouh Second">
                                    Al Safouh Second
                                </option>
                                <option value="Al Satwa">Al Satwa</option>
                                <option value="Al Shindagha">
                                    Al Shindagha
                                </option>
                                <option value="Al Souq Al Kabeer">
                                    Al Souq Al Kabeer
                                </option>
                                <option value="Al Twar First">
                                    Al Twar First
                                </option>
                                <option value="Al Twar Second">
                                    Al Twar Second
                                </option>
                                <option value="Al Twar Third">
                                    Al Twar Third
                                </option>
                                <option value="Al Warqa'a Fifth">
                                    Al Warqa'a Fifth
                                </option>
                                <option value="Al Warqa'a First">
                                    Al Warqa'a First
                                </option>
                                <option value="Al Warqa'a Fourth">
                                    Al Warqa'a Fourth
                                </option>
                                <option value="Al Warqa'a Second">
                                    Al Warqa'a Second
                                </option>
                                <option value="Al Warqa'a Third">
                                    Al Warqa'a Third
                                </option>
                                <option value="Al Wasl">Al Wasl</option>
                                <option value="Al Waheda">Al Waheda</option>
                                <option value="Arabian Ranches">
                                    Arabian Ranches
                                </option>
                                <option value="Ayal Nasir">Ayal Nasir</option>
                                <option value="Aleyas">Aleyas</option>
                                <option value="Bu Kadra">Bu Kadra</option>
                                <option value="Downtown Dubai">
                                    Downtown Dubai
                                </option>
                                <option value="Dubai Investment park First">
                                    Dubai Investment park First
                                </option>
                                <option value="Dubai Investment Park Second">
                                    Dubai Investment Park Second
                                </option>
                                <option value="Emirates Hill First">
                                    Emirates Hill First
                                </option>
                                <option value="Emirates Hill Second">
                                    Emirates Hill Second
                                </option>
                                <option value="Emirates Hill Third">
                                    Emirates Hill Third
                                </option>
                                <option value="Hatta">Hatta</option>
                                <option value="Hor Al Anz">Hor Al Anz</option>
                                <option value="Hor Al Anz East">
                                    Hor Al Anz East
                                </option>
                                <option value="Jebel Ali 1">Jebel Ali 1</option>
                                <option value="Jebel Ali 2">Jebel Ali 2</option>
                                <option value="Jebel Ali Industrial">
                                    Jebel Ali Industrial
                                </option>
                                <option value="Jebel Ali Palm">
                                    Jebel Ali Palm
                                </option>
                                <option value="Jumeira First">
                                    Jumeira First
                                </option>
                                <option value="Jumeira Second">
                                    Jumeira Second
                                </option>
                                <option value="Jumeira Third">
                                    Jumeira Third
                                </option>
                                <option value="Marsa Dubai">Marsa Dubai</option>
                                <option value="Mirdif">Mirdif</option>
                                <option value="Muhaisanah Fourth">
                                    Muhaisanah Fourth
                                </option>
                                <option value="Muhaisanah Second">
                                    Muhaisanah Second
                                </option>
                                <option value="Muhaisanah Third">
                                    Muhaisanah Third
                                </option>
                                <option value="Muhaisnah First">
                                    Muhaisnah First
                                </option>
                                <option value="Nad Al Hammar">
                                    Nad Al Hammar
                                </option>
                                <option value="Nadd Al Shiba Fourth">
                                    Nadd Al Shiba Fourth
                                </option>
                                <option value="Nadd Al Shiba Second">
                                    Nadd Al Shiba Second
                                </option>
                                <option value="Nadd Al Shiba Third">
                                    Nadd Al Shiba Third
                                </option>
                                <option value="Nad Shamma">Nad Shamma</option>
                                <option value="Naif">Naif</option>
                                <option value="Palm Jumeira">
                                    Palm Jumeira
                                </option>
                                <option value="Port Saeed">Port Saeed</option>
                                <option value="Ras Al Khor">Ras Al Khor</option>
                                <option value="Ras Al Khor Industrial First">
                                    Ras Al Khor Industrial First
                                </option>
                                <option value="Ras Al Khor Industrial Second">
                                    Ras Al Khor Industrial Second
                                </option>
                                <option value="Ras Al Khor Industrial Third">
                                    Ras Al Khor Industrial Third
                                </option>
                                <option value="Rigga Al Buteen">
                                    Rigga Al Buteen
                                </option>
                                <option value="Trade Centre 1">
                                    Trade Centre 1
                                </option>
                                <option value="Trade Centre 2">
                                    Trade Centre 2
                                </option>
                                <option value="Umm Al Sheif">
                                    Umm Al Sheif
                                </option>
                                <option value="Umm Hurair First">
                                    Umm Hurair First
                                </option>
                                <option value="Umm Hurair Second">
                                    Umm Hurair Second
                                </option>
                                <option value="Umm Ramool">Umm Ramool</option>
                                <option value="Umm Suqeim First">
                                    Umm Suqeim First
                                </option>
                                <option value="Umm Suqeim Second">
                                    Umm Suqeim Second
                                </option>
                                <option value="Umm Suqeim Third">
                                    Umm Suqeim Third
                                </option>
                                <option value="Wadi Alamardi">
                                    Wadi Alamardi
                                </option>
                                <option value="Warsan First">
                                    Warsan First
                                </option>
                                <option value="Warsan Second">
                                    Warsan Second
                                </option>
                                <option value="Za'abeel First">
                                    Za'abeel First
                                </option>
                                <option value="Za'abeel Second">
                                    Za'abeel Second
                                </option>
                            </Select>
                            <Input type="textarea" label="Overview" />

                            <Input
                                type="checkbox"
                                name="accept_privacy_policy"
                                required
                            >
                                I agree to the propertyeportal
                                <Link to="/privacy-policy">privacy policy</Link>
                            </Input>

                            <Button>Send</Button>
                        </Form>
                        <p>
                            **It is important that all parties in the market
                            maintain the highest ethical standard. Abusing the
                            use of the above service by cutting of Registered
                            brokers or other authorized market players is
                            unethical and illegal. This service is meant to
                            protect the rights of and facilitate easy sales for
                            sellers and buyers. We support the real estate
                            communities which ethical real estate brokers are a
                            major player and contributor to the prosperity of.
                        </p>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <QuestionImage className={styles.question} />
                    </div>
                </div>
            </div>
        </div>
    )
}
